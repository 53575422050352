import Vue from 'vue'
import Router from 'vue-router'
import { LoadingBar } from 'view-design'
import { getDocumentTitle } from '../utils'

Vue.use(Router)

// const subfix = '/:bundleId/:osType/:appVersion/:deviceId/:userId/:token'

const routes = [
    {
        path: '/',
        meta: { title: '海棠' },
        component: () => import('../views/www/wwwMobile.vue')
    },
    {
        path: '/xy',
        meta: { title: '探花' },
        component: () => import('../views/www/wwwxy.vue')
    },
    {
        path: '/tx',
        meta: { title: '天仙' },
        component: () => import('../views/www/wwwtx.vue')
    },
    {
        path: '/qw',
        meta: { title: '蔷薇' },
        component: () => import('../views/www/wwwqw.vue')
    },
    {
        path: '/ds',
        meta: { title: '小花车' },
        component: () => import('../views/www/wwwds.vue')
    },
    {
        path: '/mobile/home',
        meta: { title: '海棠' },
        component: () => import('../views/www/wwwMobile.vue')
    },
    {
        path: '/gift/list',
        meta: { title: '我收到的礼物' },
        component: () => import('../views/gift/giftList.vue'),
    },
    {
        path: '/agreement',
        meta: { title: '用户协议及隐私条款' },
        component: () => import('../views/agreement/agreement.vue'),
    },
    {
        path: '/agreementxy',
        meta: { title: '用户协议及隐私条款' },
        component: () => import('../views/agreement/agreementXy.vue'),
    },
    {
        path: '/agreementtx',
        meta: { title: '用户协议及隐私条款' },
        component: () => import('../views/agreement/agreementTx.vue'),
    },
    {
        path: '/agreementqw',
        meta: { title: '用户协议及隐私条款' },
        component: () => import('../views/agreement/agreementQw.vue'),
    },
    {
        path: '/videoCallAgreement',
        meta: { title: '视频通话协议' },
        component: () => import('../views/agreement/videoCall.vue'),
    },
    {
        path: '/call/record',
        meta: { title: '通话记录' },
        component: () => import('../views/call/callRecord.vue'),
    },
    {
        path: '/income/myIncome',
        meta: { title: '我的收益' },
        component: () => import('../views/income/income.vue'),
    },
    {
        path: '/income/sellerIncome',
        meta: { title: '我的账户' },
        component: () => import('../views/income/sellerIncome.vue'),
    },
    {
        path: '/withdraw/record',
        meta: { title: '我的收益' },
        component: () => import('../views/withdraw/withdrawRecord.vue'),
    },
    {
        path: '/withdraw/apply',
        meta: { title: '我的收益' },
        component: () => import('../views/withdraw/apply.vue'),
    },
    {
        path: '/withdraw/bindAccount',
        meta: { title: '我的收益' },
        component: () => import('../views/withdraw/bindAccount.vue'),
    },
    {
        path: '/recharge',
        meta: { title: '充值' },
        component: () => import('../views/recharge/recharge.vue'),
    },
    {
        path: '/justRecharge',
        meta: { title: '充值' },
        component: () => import('../views/recharge/justRecharge.vue'),
    },
    {
        path: '/rechargeDetail',
        meta: { title: '海棠充值付款页' },
        component: () => import('../views/recharge/rechargeDetail.vue'),
    },
    {
        path: '/wechatRecharge',
        meta: { title: '充值' },
        component: () => import('../views/recharge/wechatRecharge.vue'),
    },
    {
        path: '/recharge/result',
        meta: { title: '充值结果' },
        component: () => import('../views/recharge/result.vue'),
    },
    {
        path: '/convention',
        meta: { title: '文明公约' },
        component: () => import('../views/convention/convention.vue'),
    },
    {
        path: '/conventionxy',
        meta: { title: '文明公约' },
        component: () => import('../views/convention/conventionXy.vue'),
    },
    {
        path: '/conventiontx',
        meta: { title: '文明公约' },
        component: () => import('../views/convention/conventionTx.vue'),
    },
    {
        path: '/conventionqw',
        meta: { title: '文明公约' },
        component: () => import('../views/convention/conventionQw.vue'),
    },
    {
        path: '/inviteEarnGold/rule',
        meta: { title: '活动规则' },
        component: () => import('../views/inviteEarnGold/rule.vue'),
    },
    {
        path: '/inviteEarnGold/qrCode',
        meta: { title: '我的二维码' },
        component: () => import('../views/inviteEarnGold/myQRCode.vue'),
    },
    {
        path: '/inviteEarnGold/invite',
        meta: { title: '邀请加入' },
        component: () => import('../views/inviteEarnGold/invite.vue'),
    },
    {
        path: '/performance',
        meta: { title: '业绩报告' },
        component: () => import('../views/performance/performance.vue'),
    },
    {
        path: '/inviteJoinActive',
        meta: { title: '邀请参加' },
        component: () => import('../views/performance/inviteJoin.vue'),
    },
    {
        path: '/userSummarized',
        meta: { title: '用户资料概述' },
        component: () => import('../views/user/summarized.vue'),
    },
    {
        path: '/closeSpecialTimeSwitch',
        meta: { title: '恢复充值' },
        component: () => import('../views/app/closeSpecialTimeSwitch.vue'),
    },
    {
        path: '/feishu',
        meta: { title: '飞书操作' },
        component: () => import('../views/app/feishu.vue'),
    },
    {
        path: '/accountLog',
        meta: {title: '账单记录'},
        component: () => import('../views/user/bill.vue'),
    },
    {
        path: '/privacyTerms',
        meta: {title: '隐私政策'},
        component: () => import('../views/agreement/privacyTerms.vue'),
    },
    {
        path: '/userAgreement',
        meta: {title: '用户协议'},
        component: () => import('../views/agreement/userAgreement.vue'),
    },
]

const router = new Router({
    mode: 'history',
    routes,
})

router.beforeEach(async (to, from, next) => {
    document.title = getDocumentTitle(to.meta.title)
    LoadingBar.start()
    next()
})

router.afterEach(() => {
    LoadingBar.finish()
})

export default router